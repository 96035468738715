import { Link } from "gatsby"
import React from "react"
import Logo from '../images/logowhite.svg'
import styled from 'styled-components'

const Footer = () => (
  <SiteFooter className="site-footer">
    <div className="site-footer__logo">
      <Link to="/"><img src={Logo} alt="MyServicePak Logo" /></Link>
    </div>
    <div className="site-footer__contact">
      <ul>
        <li><a href="mailto:myservicepak@ehsmomentum.com">MYSERVICEPAK@EHSMOMENTUM.COM</a></li>
        <li>|</li>
        <li>EHS MOMENTUM, LLC</li>
        <li>|</li>
        <li>3900 ELM ST. DALLAS, TX 75226</li>
        <li>|</li>
        <li><a href="tel:469-999-2500">469-999-2500</a></li>
      </ul>
    </div>
    <div className="site-footer__copyright">
      <p>© {new Date().getFullYear()} EHS Momentum, LLC</p>
      <ul>
        <li><a href="/sitemap">SITEMAP</a></li>
        <li>|</li>
        <li><a href="/privacy-policy">PRIVACY POLICY</a></li>
      </ul>
    </div>
  </SiteFooter>
)

export default Footer

const SiteFooter = styled.footer`
  background: ${props => props.theme.color.red__400};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.spacing.large};
  color: ${props => props.theme.color.white};
  text-align: center;

  .site-footer__logo {
    width: 250px;
  }

  ul {
      list-style: none;
      margin-left: 0;
    }

    ul>li {
      display: inline-block;
      margin: 0 ${props => props.theme.spacing.small};
    }

    a {
      color: ${props => props.theme.color.white};
      text-decoration: none;
    }

  .site-footer__contact {
    font-weight: ${props => props.theme.font.weight.bold};

    a {
      font-weight: ${props => props.theme.font.weight.bold};
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      ul>li {
        display: block;

        &:nth-child(2n) {
          display: none;
        }
      }
    }
  }

  .site-footer__copyright {
    font-size: ${props => props.theme.font.size.xsmall};

    p {
      margin-bottom: 0;
    }

    ul {
      margin-bottom: 0;
    }

    ul>li {
      margin: 0 ${props => props.theme.spacing.small};
    }
  }
`

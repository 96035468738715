import { Link } from "gatsby"
import React from "react"
import Logo from '../images/logocolor.svg'
import styled from 'styled-components'

const Header = () => {
  const [ mobileMenu, setMobileMenu ] = React.useState(true);

  return (
    <SiteHeader className="site-header">
      <div className="site-header__logo">
        <Link to="/"><img src={Logo} alt="MyServicePak Logo" /></Link>
      </div>
      <div className="site-header__content" aria-hidden={mobileMenu}>
        <nav className="site-header__nav">
          <ul>
            <li><Link to="/" className="menu-item">Home</Link></li>
            <li><Link to="/tool/" className="menu-item">The Tool</Link></li>
            <li><Link to="/get-started/" className="menu-item">Get Started</Link></li>
          </ul>
        </nav>
        <div className="site-header__more-information">
          <p>For more information</p>
          <a href="mailto:myservicepak@ehsmomentum.com">MYSERVICEPAK@EHSMOMENTUM.COM</a>
        </div>
      </div>
      <div className="site-header__toggle">
        <ul onClick={() => setMobileMenu(!mobileMenu)}>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </SiteHeader>
  )
}

export default Header

const SiteHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  height: 100px;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoint.widescreen}) {
    height: auto;
    padding: ${props => props.theme.spacing.medium};
    padding-left: 0;
  }

  .site-header__logo {
    flex: 0 1 300px;
    width: 300px;

    img {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none;
    margin-left: 0;
  }

  .site-header__content {
    height: 0;
    overflow: none;
  }

  a {
    font-weight: ${props => props.theme.font.weight.bold};
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .menu-item {
    color: ${props => props.theme.color.blue__400};
  }

  .site-header__toggle {
    background: ${props => props.theme.color.blue__400};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${props => props.theme.spacing.small};
    height: 100%;

    ul {
      margin-bottom: 0;
      cursor: pointer;
    }
    
    ul>li {
      background: ${props => props.theme.color.white};
      border-radius: 10px;
      height: 3px;
      margin: 4px;
      width: 30px;
    }
  }

  /* Mobile nav */
  @media (max-width: ${props => props.theme.breakpoint.widescreen}) {
    .site-header__content {
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
      z-index: 99;
    }
    
    .site-header__content[aria-hidden=true] {
      height: 0;
      overflow: hidden;
    }

    .site-header__content[aria-hidden=false] {
      height: auto;
      overflow: auto;
    }

    .site-header__nav {
      background: ${props => props.theme.color.white};

      ul {
        margin-bottom: 0;
      }
      
      ul>li {
        border-bottom: 1px solid ${props => props.theme.color.red__400};
        padding: ${props => props.theme.spacing.medium};
        margin: 0;
      }
    }

    .site-header__more-information {
      background: ${props => props.theme.color.red__400};
      padding: ${props => props.theme.spacing.medium};

      p {
        display: none;
      }

      a {
        color: ${props => props.theme.color.white};
      }
    }
  }

  /* widescreen nav */
  @media (min-width: ${props => props.theme.breakpoint.widescreen}) {
    .site-header__toggle {
      display: none;
    } 

    .site-header__content {
      height: auto;
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: -${props => props.theme.spacing.large};
    }

    .site-header__nav {

      ul {
        margin-bottom: 0;
      }

      ul>li {
        display: inline-block;
        margin: 0 ${props => props.theme.spacing.medium};
      }
    }

    .site-header__more-information {
      flex: 0 1 auto;
      text-align: right;

      p {
        margin-bottom: 0;
        font-weight: ${props => props.theme.font.weight.bold};
      }

      a {
        color: ${props => props.theme.color.red__400};
      }
    }
  }
`
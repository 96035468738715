/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import styled from "styled-components"
import Header from "./header"
import Footer from "./footer"
import theme from "../../theme"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Wrapper>
          <main className="site-content">{children}</main>
        </Wrapper>
        <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Wrapper = styled.div`
  position: relative;

  h2,
  h3,
  h6,
  p {
    color: ${props => props.theme.color.blue__400};
    margin: 0;
  }

  h2 {
    font-size: ${props => props.theme.font.size.xlarge};
  }

  h3 {
    font-size: ${props => props.theme.font.size.large};
  }

  h6 {
    color: ${props => props.theme.color.white};
    font-family: ${props => props.theme.font.family.body};
  }

  p {
    color: ${props => props.theme.color.black};
    font-size: ${props => props.theme.font.size.medium};
  }

  img {
    margin: 0;
  }
`

/**
 * Style guideline provided via ThemeProvider to supply sitewide site styles
 */
export default {
  color: {
    blue__400: "rgb(21,81,116)",
    red__400: "rgb(185,82,48)",
    charcoal: "rgb(62,62,63)",
    black: "rgb(87,87,86)",
    white: "rgb(255,255,255)",
    lightgrey: "rgb(230,231,233)",
    shadowgrey: "rgba(128,128,128,0.6)",
  },
  font: {
    family: {
      heading: "Oswald, sans-serif", // 700 weight
      body: "Source Sans Pro, sans-serif", // 400, 700 weight
    },
    size: {
      small: "0.9rem",
      medium: "1.1rem",
      large: "2rem",
      xlarge: "2.9rem",
    },
    weight: {
      regular: "400",
      bold: "700",
    },
  },
  spacing: {
    small: "0.5rem",
    medium: "1rem",
    large: "1.5rem",
    xlarge: "2rem",
    xxlarge: "4rem",
  },
  breakpoint: {
    mobile: "400px",
    largePhone: "500px",
    desktop: "768px",
    widescreen: "1024px",
    sectionWidth: "1315px",
  },
  button: {
    height: "32px",
    width: "122px",
    fontSizeLarge: "1rem",
    fontSizeXLarge: "1.1rem",
  },
  icon: {
    large: {
      height: "110px",
      width: "260px",
      imgsize: "60px",
      imgsizeWhite: "85px",
    },
    small: {
      height: "100px",
      width: "220px",
      imgsize: "50px",
      imgsizeWhite: "65px",
    },
  },
  panel: {
    height: "328px",
    heightTablet: "280px",
    heightMobile: "220px",
    width: "323px",
  },
  section: {
    padding: "30px 0",
    width: "1315px",
  },
  maxWidth: "960px",
}
